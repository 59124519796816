import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { SET_LAYOUT } from '@/store/mutation-type'
// import { userService } from '../services'

// import AdalAuthentication from '@/adal-auth'
// import { Keys, ActionTypes } from '../utils/constants'

import Home from '../views/Home.vue'
import About from '../views/About.vue'
// import Department from '../views/department/index.vue'
// import DepartmentApproval from '../views/department/DepartmentApproval.vue'
// import DepartmentList from '../views/department/DepartmentList.vue'
const RoleView = () =>
  import(/* webpackChunkName: "adminViews" */ '@/views/role-view')
const UserView = () =>
  import(/* webpackChunkName: "adminViews" */ '@/views/user-view')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { authorize: [] }
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/admin/users',
    name: 'users',
    component: UserView
  },
  {
    path: '/admin/roles',
    name: 'roles',
    component: RoleView
  },
  {
    path: '/department/add',
    name: 'Department',
    component: () =>
    import(
      '../views/department/DepartmentAdd.vue'
    )
  },
  {
    path: '/department/approvals',
    component: () =>
    import(
      '../views/department/DepartmentApproval.vue'
    )
  },
  {
    path: '/department',
    component: () =>
    import(
      '../views/department/index.vue'
    )
  },
  {
    path: '/contract-employer/approvals',
    name: 'ContractEmployerApproval',
    component: () =>
      import(
        '../views/contract-employer/ContractEmployerApproval.vue'
      )
  },
  {
    path: '/contract-employer/list',
    name: 'ContractEmployerList',
    component: () =>
      import(
        '../views/contract-employer/ContractEmployerList.vue'
      )
  },
  {
    path: '/contract-employer/complete',
    name: 'ContractEmployerComplete',
    component: () =>
      import(
        '../views/contract-employer/CompleteContractEmployerKyc.vue'
      )
  },
  {
    path: '/contract-employer-user/add',
    name: 'AddContractEmployerUser',
    component: () =>
      import(
        '../views/contract-employer/CompleteContractEmployerUser.vue'
      )
  },
  {
    path: '/contract-employer-user',
    name: 'ContractEmployerUser',
    component: () =>
      import(
        '../views/contract-employer/ContractEmployerUserList.vue'
      )
  },
  {
    path: '/contract-employer',
    name: 'ContractEmployer',
    component: () =>
      import(
        '../views/contract-employer/index.vue'
      )
  },
  {
    path: '/contract-employer/:id',
    name: 'ContractEmployerEdit',
    component: () =>
      import(
        '../views/contract-employer/ContractEmployerEdit.vue'
      )
  },
  {
    path: '/job-title/approvals',
    component: () =>
      import(
        '../views/job-title/JobTitleApproval.vue'
      )
  },
  {
    path: '/job-title/add',
    component: () =>
      import(
        '../views/job-title/JobTitleAdd.vue'
      )
  },
  {
    path: '/job-title',
    name: 'JobTitle',
    component: () =>
      import('../views/job-title/index.vue')
  },
  {
    path: '/job-title/allowance',
    name: 'JobTitleAllowance',
    component: () =>
      import('../views/unionMember/index.vue')
  },
  {
    path: '/job-title/allowance/add',
    name: 'AddJobTitleAllowance',
    component: () =>
      import('../views/unionMember/add.vue')
  },
  {
    path: '/job-title/allowance/audit',
    name: 'JobTitleAllowanceAudit',
    component: () =>
      import('../views/unionMember/audit.vue')
  },
  {
    path: '/job-title/allowance/approval',
    name: 'JobTitleAllowanceApproval',
    component: () =>
      import('../views/unionMember/unionMemberApproval.vue')
  },
  {
    path: '/work-area/approvals',
    name: 'WorkAreaApprovals',
    component: () =>
      import(
        '../views/work-area/WorkAreaApproval.vue'
      )
  },
  {
    path: '/work-area/list',
    name: 'WorkAreaList',
    component: () =>
      import(
        '../views/work-area/WorkAreaList.vue'
      )
  },
  {
    path: '/work-area',
    name: 'WorkAreaTitle',
    component: () =>
      import('../views/work-area/index.vue')
  },
  {
    path: '/location/approvals',
    name: 'locationApproval',
    component: () =>
      import('../views/location/LocationApproval.vue')
  },
  {
    path: '/location/add',
    name: 'locationAdd',
    component: () =>
      import(
        '../views/location/LocationAdd.vue'
      )
  },
  {
    path: '/location',
    name: 'Locaton',
    component: () =>
      import('../views/location/index.vue')
  },
  {
    path: '/location-allowance',
    name: 'LocatonAllowance',
    component: () =>
      import('../views/location-allowance/index.vue')
  },
  {
    path: '/personnel-data/approvals',
    component: () =>
      import('../views/personnel/PersonnelApproval.vue')
  },
  {
    path: '/personnel-data/move',
    component: () =>
      import('../views/personnel/movePersonnel.vue')
  },
  {
    path: '/personnel-data/move/list',
    component: () =>
      import('../views/personnel/PersonnelMoveList.vue')
  },
  {
    path: '/personnel/move/approvals',
    component: () =>
      import('../views/personnel/PersonnelMoveApproval.vue')
  },
  {
    path: '/personnel-data/list',
    component: () =>
      import('../views/personnel/PersonnelList.vue')
  },
  {
    path: '/personnel-data',
    name: 'PersonnelData',
    component: () =>
      import('../views/personnel/index.vue')
  },
  {
    path: '/personnel-data/edit/:id',
    name: 'PersonnelDataEdit',
    component: () =>
      import('../views/personnel/editPersonnel.vue')
  },
  {
    path: '/personnel-deduction/add',
    name: 'AddPersonnelDeduction',
    component: () =>
      import('../views/deduction/Add.vue')
  },
  {
    path: '/personnel-deduction/edit/:id',
    name: 'EditPersonnelDeduction',
    component: () =>
      import('../views/deduction/Edit.vue')
  },
  {
    path: '/pending-deduction',
    name: 'PendingDeductionList',
    component: () =>
      import('../views/deduction/Pending.vue')
  },
  {
    path: '/personnel-deduction',
    name: 'PersonnelDeductionList',
    component: () =>
      import('../views/deduction/index.vue')
  },
  {
    path: '/salary/approvals',
    name: 'SalaryApproval',
    component: () =>
      import(
        '../views/salary/SalaryApproval.vue'
      )
  },
  {
    path: '/salary/list',
    name: 'SalaryList',
    component: () =>
      import(
        '../views/salary/SalaryList.vue'
      )
  },
  {
    path: '/union-membership',
    name: 'UnionMembership',
    component: () =>
      import(
        '../views/unionMember/union.vue'
      )
  },
  {
    path: '/union-membership/approvals',
    name: 'UnionMembershipApproval',
    component: () =>
      import(
        '../views/unionMember/approveUnionMembership.vue'
      )
  },
  {
    path: '/union-membership/index',
    name: 'UnionMembershipList',
    component: () =>
      import(
        '../views/unionMember/pendingUnionMembership.vue'
      )
  },
  {
    path: '/income-tax',
    name: 'IncomeTaxBand',
    component: () =>
      import(
        '../views/IncomeTaxBand/index.vue'
      )
  },
  {
    path: '/income-tax/pending',
    name: 'pendingIncomeTaxBand',
    component: () =>
      import(
        '../views/IncomeTaxBand/pending.vue'
      )
  },
  {
    path: '/salary',
    name: 'Salary',
    component: () =>
      import('../views/salary/index.vue')
  },
  {
    path: '/salary/add',
    name: 'SalaryAdd',
    component: () =>
      import('../views/salary/add.vue')
  },
  {
    path: '/benefit-setup/approvals',
    name: 'benefitApproval',
    component: () =>
      import(
        '../views/benefits/BenefitApproval.vue'
      )
  },
  {
    path: '/benefit-setup/add',
    name: 'BenefitSetupAdd',
    component: () =>
      import('../views/benefits/add.vue')
  },
  {
    path: '/benefit-setup',
    name: 'BenefitSetup',
    component: () =>
      import('../views/benefits/index.vue')
  },
  {
    path: '/rotation/approvals',
    name: 'rotationApproval',
    component: () =>
      import(
        '../views/rotation/RotationApproval.vue'
      )
  },
  {
    path: '/rotation/add',
    name: 'rotationAdd',
    component: () =>
      import(
        '../views/rotation/RotationAdd.vue'
      )
  },
  {
    path: '/rotation',
    name: 'Rotation',
    component: () =>
      import('../views/rotation/index.vue')
  },
  {
    path: '/request',
    name: 'Request',
    component: () =>
      import('../views/requests/index.vue')
  },
  {
    path: '/timesheet',
    name: 'Timesheet',
    component: () =>
      import('../views/timesheet/index.vue')
  },
  {
    path: '/timesheet/download',
    name: 'DownloadTimesheet',
    component: () =>
      import('../views/timesheet/Download.vue')
  },
  {
    path: '/timesheet/pending',
    name: 'TimesheetPending',
    component: () =>
      import('../views/timesheet/pending.vue')
  },
  {
    path: '/request/approvals',
    name: 'RequestApprovals',
    component: () =>
      import('../views/requests/RequestApproval.vue')
  },
  {
    path: '/timesheet/upload',
    name: 'UploadTimesheet',
    component: () =>
      import('../views/timesheet/UploadTimesheet.vue')
  },
  {
    path: '/timesheet/edit',
    name: 'EditTimesheet',
    component: () =>
      import('../views/timesheet/Edit.vue')
  },
  {
    path: '/timesheet/view',
    name: 'ViewTimesheet',
    component: () =>
      import('../views/timesheet/View.vue')
  },
  {
    path: '/ppe-request',
    name: 'PpeRequest',
    component: () =>
      import('../views/ppeRequest/index.vue')
  },
  {
    path: '/ppe-request/add',
    name: 'PpeRequestAdd',
    component: () =>
      import('../views/ppeRequest/new.vue')
  },
  {
    path: '/ppe-request/approvals',
    name: 'PpeRequestApprovals',
    component: () =>
      import('../views/ppeRequest/PpeApproval.vue')
  },
  // module approvers routes
  {
    path: '/module-approvers',
    name: 'Moduleapprovals',
    component: () =>
      import('../views/module-approvers/index.vue')
  },
  {
    path: '/module-pending',
    name: 'ModuleApprovalsPending',
    component: () =>
      import('../views/module-approvers/pending.vue')
  },
  // Module approvers routes End

  {
    path: '/training-request',
    name: 'TrainingRequest',
    component: () =>
      import('../views/trainingRequest/index.vue')
  },
  {
    path: '/training-request/approvals',
    name: 'TrainingRequestApproval',
    component: () =>
      import('../views/trainingRequest/TrainingApproval.vue')
  },
  {
    path: '/training-request/add',
    name: 'TrainingRequestAdd',
    component: () =>
      import('../views/trainingRequest/add.vue')
  },
  {
    path: '/exit',
    name: 'exit',
    component: () =>
      import('../views/exit/index.vue')
  },
  {
    path: '/exit/add',
    name: 'exitAdd',
    component: () =>
      import('../views/exit/addExit.vue')
  },
  {
    path: '/exit/approvals',
    name: 'exitApprovals',
    component: () =>
      import('../views/exit/exitApprovals.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () =>
      import('../views/search/index.vue')
  },
  {
    path: '/medical/add',
    name: 'AddMedicalRecord',
    component: () =>
      import('../views/medical-records/Add.vue')
  },
  {
    path: '/medical/pending',
    name: 'PendingMedicalRecord',
    component: () =>
      import('../views/medical-records/approve.vue')
  },
  {
    path: '/medical/list',
    name: 'MedicalRecordList',
    component: () =>
      import('../views/medical-records/index.vue')
  },
  {
    path: '/states',
    name: 'states',
    component: () =>
      import('../views/state/index.vue')
  },
  {
    path: '/states/approvals',
    name: 'statesApprovals',
    component: () =>
      import('../views/state/StateApproval.vue')
  },
  {
    path: '/states/add',
    name: 'statesAdd',
    component: () =>
      import('../views/state/StateAdd.vue')
  },
  {
    path: '/leave',
    name: 'Leave',
    component: () =>
      import('../views/leave/index.vue')
  },
  {
    path: '/leave/recall/approve',
    name: 'ApproveLeaveRecall',
    component: () =>
      import('../views/leave/ApproveRecall.vue')
  },
  {
    path: '/leave/add',
    name: 'LeaveAdd',
    component: () =>
      import('../views/leave/add.vue')
  },
  {
    path: '/leave/recall',
    name: 'LeaveRecall',
    component: () =>
      import('../views/leave/LeaveRecall.vue')
  },
  {
    path: '/leave/approvals',
    name: 'LeaveApprovals',
    component: () =>
      import('../views/leave/LeaveApproval.vue')
  },
  {
    path: '/leave/:id',
    name: 'LeaveView',
    component: () =>
      import('../views/leave/view.vue')
  },
  {
    path: '/allowance',
    name: 'allowance',
    component: () =>
      import('../views/allowance/index.vue')
  },
  {
    path: '/allowance/approvals',
    name: 'AllowanceApproval',
    component: () =>
      import('../views/allowance/AllowanceApproval.vue')
  },
  {
    path: '/allowance/add',
    name: 'AddAllowance',
    component: () =>
      import('../views/allowance/add.vue')
  },
  {
    path: '/allowance/vacation-allowance',
    name: 'AddVacationAllowance',
    component: () =>
      import('../views/allowance/vacations/vacationAllowance.vue')
  },
  {
    path: '/terminal-benefits',
    name: 'TerminalBenefits',
    component: () =>
      import('../views/terminal-benefits/index.vue')
  },
  {
    path: '/terminal-benefits/rate',
    name: 'TerminalBenefitsRate',
    component: () =>
      import('../views/terminal-benefits/Terminal-benefits-rate.vue')
  },
  {
    path: '/terminal-benefits/rate/approvals',
    name: 'TerminalBenefitsRateApprovals',
    component: () =>
      import('../views/terminal-benefits/Terminal-benefits-rate-approval.vue')
  },
  {
    path: '/end-of-contract',
    name: 'EndofContract',
    component: () => import ('../views/end-of-contract/index.vue')
  },
  {
    path: '/requisition',
    name: 'Requisition',
    component: () => import ('../views/requisition/RequisitionList.vue')
  },
  {
    path: '/requisition/add',
    name: 'RequisitionAdd',
    component: () =>
      import('../views/requisition/index.vue')
  },
  {
    path: '/requisition/approval',
    name: 'RequisitionApproval',
    component: () =>
      import('../views/requisition/RequisitionApproval.vue')
  },
  {
    path: '/holiday/approvals',
    name: 'HolidayApproal',
    component: () => import ('../views/holiday/holidayApproval.vue')
  },
  {
    path: '/holiday/add',
    name: 'HolidayAdd',
    component: () => import ('../views/holiday/holidayAdd.vue')
  },
  {
    path: '/holiday',
    name: 'Holiday',
    component: () => import ('../views/holiday/index.vue')
  },
  {
    path: '/assign-roles',
    name: 'AssignRoles',
    component: () => import ('../views/role/index.vue')
  },
  {
    path: '/report/training',
    name: 'TrainingReport',
    component: () => import ('../views/reports/Training.vue')
  },
  {
    path: '/report/salaryHistory',
    name: 'TrainingReport',
    component: () => import ('../views/reports/SalaryHistory.vue')
  },
  {
    path: '/report/ppe',
    name: 'PPEReport',
    component: () => import ('../views/reports/PPE.vue')
  },
  {
    path: '/report/allowance',
    name: 'AllowanceReport',
    component: () => import ('../views/reports/Allowance.vue')
  },
  {
    path: '/report/timeSheet',
    name: 'TimeSheetReport',
    component: () => import ('../views/reports/Timesheet.vue')
  },
  {
    path: '/report/leave',
    name: 'LeaveReport',
    component: () => import ('../views/reports/Leave.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import ('../Login.vue'),
    meta: { authorize: [] }
  },
  {
    path: '/create-user',
    name: 'CreateUser',
    component: () => import ('../views/user/add.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import ('../views/user/listUser.vue')
  },
  {
    path: '/user/approvals',
    name: 'UserApprovals',
    component: () => import ('../views/user/userApproval.vue')
  },
  {
    path: '/salary-period',
    name: 'SalaryPeriod',
    component: () => import ('../views/salaryPeriod/index.vue')
  },
  {
    path: '/payment-schedules/:id',
    name: 'PaymentSchedules',
    component: () => import ('../views/Payment/index.vue')
  },
  {
    path: '/medical/screening-types',
    name: 'MedicalScreeningCategory',
    component: () => import('../views/medical-screening-category/index.vue')
  },
  {
    path: '/medical/screening-sub-category',
    name: 'MedicalScreeningSubCategory',
    component: () => import('../views/medical-screening-category/AddSubCategory.vue')
  },
  {
    path: '/app-doc',
    name: 'AppDocumentation',
    component: () => import('../views/app-documentation/index.vue')
  },
  {
    path: '/report/personnel',
    name: 'PersonnelReport',
    component: () => import('../views/reports/Personnel.vue')
  },
  {
    path: '/report/SummaryCount',
    name: 'SummaryCountReport',
    component: () => import('../views/reports/SummaryCount.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  beforeEach: (to, from, next) => {
    let layout = 'layout12'
    if (to.matched.some(record => record.meta.layout)) {
      layout = to.matched[0].meta.layout
    }
    if (layout !== store.layoutModule.currentLayout) {
      store.commit(SET_LAYOUT, layout)
    }
    next()
  }
})

router.beforeEach((to, from, next) => {
  // const profile = AdalAuthentication.getUserProfile()
  // console.log('🚀 ~ file: index.js ~ line 457 ~ router.beforeEach ~ profile', profile)
  // const { authorize } = to.meta
  // userService.getCurrentUser().then(result => {
  //   console.log('🚀 ~ file: index.js ~ line 453 ~ userService.getCurrentUser ~ result', result)
  //   if (result.status === 200) {
  //     return next({ path: '/' })
  //   } else {
  //     return next({ path: '/login' })
  //     // AdalAuthentication.initialize()
  //     //   .then(() => AdalAuthentication.acquireToken(Keys.MS_GRAPH_CLIENT_ID))
  //     //   .then(msGraphToken => accountService.login(msGraphToken))
  //     //   .then(result => store.dispatch(ActionTypes.GET_CURRENT_USER))
  //     //   .then(() => {
  //     //     console.log('loggedin')
  //     //     return next({ path: '/' })
  //     //   })
  //     //   .catch(() => {
  //     //     return next({ path: '/error' })
  //     //   })
  //   }
  // }).catch(error => {
  //   console.log(error)
  //   // return next({ path: '/login' })
  // })

  next()
})

export default router
