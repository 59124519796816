'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/employees`

export default class EmployeeService extends BaseService {
  async searchEmployees (searchString) {
    return this.sendRequest('GET', `${serviceURL}/search/${searchString}`)
  }
  async searchPersonnel (searchString) {
    const value = this.sendRequest('GET', `${serviceURL}/employee/search/${searchString}`)
    return value
  }
  async getAllEmployees () {
    return this.sendRequest('GET', `${serviceURL}`)
  }
  async getEmployeesByContractEmployerId (id) {
    return this.sendRequest('GET', `${serviceURL}/${id}`)
  }
  async getEmployeesByIds (ids) {
    return this.sendRequest('GET', `${serviceURL}/ids`, ids)
  }
  async getEmployeesById (id) {
    return this.sendRequest('GET', `${serviceURL}/${id}`)
  }
  async getEmployeeListIds () {
    return this.sendRequest('GET', `${serviceURL}/ids`)
  }
  async getOrganisationIds () {
    return this.sendRequest('GET', `${serviceURL}/getOrganisationIds`)
  }
  async getEmployeesByEmployerId (employerId) {
    return this.sendRequest('GET', `${serviceURL}/employees/${employerId}`)
  }
  async getPendingRequisition () {
    return this.sendRequest('GET', `${serviceURL}/requisition/pending`)
  }
  async getPersonnelReport(data) {
    return this.sendRequest('POST', `${serviceURL}/personnelListReport`, data)
  }
  async getSummaryCountReport(id) {
    return this.sendRequest('GET', `${serviceURL}/summarycountreport/${id}`, id)
  }
}
