<template>
<nav id="site-specific-nav">
    <!-- #region site-specific navigation -->

    <!-- #region Generic Role -->
  <ul v-if="roleName !== appRoles.LCE">
    <!-- Admin Menus -->
    <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin|Finance')">
      <a href="#section5">Admin</a>
      <ul>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/user">User</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/department">Department</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/job-title">Job Title</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/union-membership">Union Membership</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/job-title/allowance">Configure Allowances</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/work-area">Work Area</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/Location">Location</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin|Finance')"><router-link to="/salary-period">Salary Period</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin|Finance')"><router-link to="/salary">Salary Component</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin|Finance')"><router-link to="/income-tax">Income Tax Band</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/rotation">Rotation</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/states">State</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/terminal-benefits">Terminal Benefit Rate</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/medical/screening-types">Medical Screening Types</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/module-approvers">Configure Approvers</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/holiday">Holiday</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/contract-employer/list">Contractor Employer</router-link></li>
      </ul>
    </li>
    <!-- Pending Menus -->
    <li v-if="userInRoles('Admin|HRCLU|HrAdmin|Finance')">
      <a href="#section5">Pending</a>
      <ul>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')"><router-link to="/user/approvals">User</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')"><router-link to="/department/approvals">Department</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')"><router-link to="/job-title/approvals">Job Title</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')"><router-link to="/union-membership/approvals">Approve Union Membership</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')"><router-link to="/job-title/allowance/approval">Configure Allowances</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')"><router-link to="/work-area/approvals">Work Area</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin|Finance')"><router-link to="/Location/approvals">Location</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin|Finance')"><router-link to="/income-tax/pending">Income Tax Band</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin|Finance')"><router-link to="/salary/approvals">Salary Component</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')"><router-link to="/exits/approvals">Exit</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')"><router-link to="/rotation/approvals">Rotation</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')"><router-link to="/states/approvals">State</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')"><router-link to="/benefit-setup/approvals">Allowance Setup</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')"><router-link to="/terminal-benefits/rate/approvals">Terminal Benefit Rate</router-link></li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|HrAdmin')"><router-link to="/module-pending">Configure Approvers</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')"><router-link to="/holiday/approvals">Holiday</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|HrAdmin')">
          <router-link to="/contract-employer/approvals">Contractor Employer</router-link>
        </li>
        <li v-if="userInRoles('Finance')">
          <router-link to="/pending-deduction">Deductions</router-link>
        </li>
      </ul>
    </li>
    <!-- Personnel Menus -->
    <li v-if="userInRoles('Admin|HRCLU|SCM|ScmAdmin|HrAdmin|Finance')">
      <a href="#personnel">Personnel</a>
      <ul>
        <li v-if="userInRoles('Admin|HRCLU|SCM|ScmAdmin|HrAdmin')">
          <router-link to="/personnel-data/list">Personnel List</router-link>
        </li>
        <li v-if="userInRoles('ScmAdmin|HrAdmin|HRCLU|SCM')">
          <router-link to="/personnel-data/approvals">Approve Personnel</router-link>
        </li>
        <li v-if="userInRoles('ScmAdmin')">
          <router-link to="/personnel-data/move">Personnel Move</router-link>
        </li>
        <li v-if="userInRoles('Admin|HRCLU|SCM|ScmAdmin|HrAdmin')">
          <router-link to="/personnel-data/move/list">Personnel Move List</router-link>
        </li>
        <li v-if="userInRoles('Admin|HRCLU|SCM|ScmAdmin')">
            <router-link to="/personnel-data">Add Personnel</router-link>
        </li>
        <li v-if="userInRoles('Admin|HRCLU|SCM|ScmAdmin|HrAdmin')">
          <router-link to="/medical/list">Medical Records</router-link>
        </li>
        <li v-if="userInRoles('Admin|SCM|ScmAdmin|Finance')">
          <router-link to="/personnel-deduction">Deductions</router-link>
        </li>
      </ul>
    </li>
    <!-- Timesheet Menus -->
    <li v-if="userInRoles('Admin|SCM|SuperVisor|HRCLU|ScmAdmin|HrAdmin')">
      <a href="#timesheet">Timesheet</a>
      <ul>
          <li v-if="userInRoles('Admin|ScmAdmin|HrAdmin')">
            <router-link to="/timesheet">Manual Entry</router-link>
          </li>
          <!--<li v-if="userInRoles('Admin|ScmAdmin|HrAdmin')">
            <router-link to="/timesheet/upload">Upload</router-link>
          </li>-->
        <li>
          <router-link to="/timesheet/view">View</router-link>
        </li>
        <li v-if="userInRoles('SCM|SuperVisor|HRCLU')">
          <router-link to="/timesheet/pending">Approve</router-link>
        </li>
      </ul>
    </li>
    <!-- Request Menus -->
    <li v-if="userInRoles('Admin|SCM|SuperVisor|HRCLU|ScmAdmin|HrAdmin|GM|Superintendent')">
      <a href="#request">Requests</a>
      <ul>
        <li v-if="userInRoles('Admin|SCM|HRCLU|ScmAdmin|HrAdmin')">
          <router-link to="/allowance">Allowance</router-link>
        </li>
        <li v-if="userInRoles('Admin|SCM|HRCLU|ScmAdmin|HrAdmin')">
          <router-link to="/ppe-request">PPE</router-link>
        </li>
        <li v-if="userInRoles('Admin|SCM|HRCLU|ScmAdmin|HrAdmin')">
          <router-link to="/training-request">Training</router-link>
        </li>
        <li v-if="userInRoles('Admin|SCM|HRCLU|ScmAdmin|HrAdmin')">
          <router-link to="/exit">Exit</router-link>
        </li>
        <li v-if="userInRoles('Admin|SCM|SuperVisor|HRCLU|HrAdmin|GM|Superintendent')">
          <router-link to="/requisition">Requisition</router-link>
        </li>
        <li v-if="userInRoles('Admin|SCM|HRCLU|ScmAdmin|HrAdmin')">
          <router-link to="/leave">Vacation</router-link>
        </li>
        <li v-if="userInRoles('Admin|SCM|HRCLU|ScmAdmin|HrAdmin')">
          <router-link to="/leave/recall">Leave Recall</router-link>
        </li>
        <li v-if="userInRoles('Admin|SCM|HRCLU|ScmAdmin|HrAdmin')">
          <router-link to="/allowance/vacation-allowance">Vacation Allowance</router-link>
        </li>
        <li v-if="userInRoles('Admin|SuperVisor|GM|Superintendent')">
          <router-link to="/personnel-data/move">Personnel Move</router-link>
        </li>
      </ul>
    </li>
    <!-- Approval Menus -->
    <li v-if="userInRoles('SCM|SuperVisor|HRCLU|HrAdmin|GM|Superintendent')">
      <a href="#request">Approvals</a>
      <ul>
        <li v-if="userInRoles('SCM|SuperVisor|HRCLU|HrAdmin|GM|Superintendent')"><router-link to="/allowance/approvals">Approve Allowance</router-link></li>
        <li v-if="userInRoles('SCM|SuperVisor|HRCLU|HrAdmin|GM|Superintendent')"><router-link to="/leave/approvals">Approve Vacation</router-link></li>
        <li v-if="userInRoles('SCM|SuperVisor|HRCLU|HrAdmin|GM|Superintendent')"><router-link to="/leave/recall/approve">Approve Vacation Recall</router-link></li>
        <li v-if="userInRoles('SCM|SuperVisor|HRCLU|HrAdmin')"><router-link to="/exit/approvals">Approve Exit</router-link></li>
        <li v-if="userInRoles('SCM|SuperVisor|HRCLU|HrAdmin|GM|Superintendent')"><router-link to="/ppe-request/approvals">Approve PPE</router-link></li>
        <li v-if="userInRoles('SCM|SuperVisor|HRCLU|HrAdmin|GM|Superintendent')"><router-link to="/training-request/approvals">Approve Training</router-link></li>
        <li v-if="userInRoles('SCM|SuperVisor|HRCLU|HrAdmin|GM|Superintendent')"><router-link to="/requisition/approval">Approve Requisition</router-link></li>
        <li v-if="userInRoles('SCM|SuperVisor|HRCLU|HrAdmin')"><router-link to="/medical/pending">Approve Medical Screening</router-link></li>
        <li v-if="userInRoles('SCM|SuperVisor|HRCLU|HrAdmin|GM|Superintendent')">
          <router-link to="/personnel/move/approvals">Approve Personnel Move</router-link>
        </li>
      </ul>
    </li>
    <li v-if="userInRoles('Finance')">
      <a href="#section4">Payment</a>
      <ul>
        <li><router-link to="/payment-schedules/1">Payment Schedule</router-link></li>
        <li><router-link to="/exits">Exits</router-link></li>
      </ul>
    </li>
    <li v-if="userInRoles('Admin|SCM|HRCLU|LCE|ScmAdmin|HrAdmin')">
      <a href="#">Reports</a>
      <ul>
        <!-- <li><router-link to="#">Salary History</router-link></li>
  <li><router-link to="#">LCE</router-link></li>
  <li><router-link to="#">Personnels</router-link></li> -->
        <li><router-link to="/report/timeSheet">Timesheets</router-link></li>
        <li><router-link to="/report/allowance">Allowance</router-link></li>
        <li><router-link to="/report/ppe">PPE</router-link></li>
        <li><router-link to="/report/training">Training</router-link></li>
        <li><router-link to="/report/salaryHistory">Salary History</router-link></li>
        <li v-if="userInRoles('Admin|HRCLU|LCE|ScmAdmin|HrAdmin')">
          <router-link to="/job-title/allowance/audit">Allowance </router-link>
        </li>
        <li><router-link to="/report/Personnel">Personnel List</router-link></li>
        <li><router-link to="/report/SummaryCount">Summary</router-link></li>
        <!-- <li><router-link to="#">Claims</router-link></li>
  <li><router-link to="#">Users</router-link></li>
  <li><router-link to="#">Timesheets (Days)</router-link></li> -->
      </ul>
    </li>
    <li>
      <a href="#help">Help</a>
      <ul>
        <li>
          <router-link to="/app-doc">User Guide</router-link>
        </li>
        <li>
          <router-link to="/faq">FAQ</router-link>
        </li>
      </ul>
    </li>
  </ul>
    <!-- #endregion Generic Role -->

    <!-- LCE Role -->
  <ul v-if="roleName === appRoles.LCE">
    <li>
      <a href="#users">User</a>
      <ul>
        <li>
          <router-link to="/contract-employer-user">Users List</router-link>
        </li>
        <li>
          <router-link to="/contract-employer-user/add">Add User</router-link>
        </li>
      </ul>
    </li>
    <li>
      <a href="#personnel">Personnel</a>
      <ul>
        <li>
          <router-link to="/personnel-data/list">Personnel List</router-link>
        </li>
        <li>
          <router-link to="/personnel-data">Add Personnel</router-link>
        </li>
        <li>
          <router-link to="/personnel-data/move/list">Personnel Move List</router-link>
        </li>
        <li>
          <router-link to="/personnel/move/approvals">Confirm Personnel Move</router-link>
        </li>
        <li>
          <router-link to="/medical/list">Medical Records</router-link>
        </li>
        <li>
          <router-link to="/personnel-deduction">Deductions</router-link>
        </li>
      </ul>
    </li>
    <li>
      <a href="#request">Requests</a>
      <ul>
        <li>
          <router-link to="/allowance">Allowance</router-link>
        </li>
        <!-- <li>
        <router-link to="/benefit-setup">Benefits</router-link>
      </li> -->
        <li>
          <router-link to="/ppe-request">PPE</router-link>
        </li>
        <li>
          <router-link to="/training-request">Training</router-link>
        </li>
        <li>
          <router-link to="/exit">Exit</router-link>
        </li>
        <li>
          <router-link to="/leave">Vacation</router-link>
        </li>
        <li>
          <router-link to="/leave/recall">Leave Recall</router-link>
        </li>
        <li>
          <router-link to="/allowance/vacation-allowance">Vacation Allowance</router-link>
        </li>
      </ul>
    </li>
    <li>
      <a href="#timesheet">Timesheet</a>
      <ul>
        <li>
          <router-link to="/timesheet">Manual Entry</router-link>
        </li>
        <li>
          <router-link to="/timesheet/edit">Edit</router-link>
        </li>
        <li>
          <router-link to="/timesheet/upload">Upload</router-link>
        </li>
        <li>
          <router-link to="/timesheet/view">View</router-link>
        </li>
      </ul>
    </li>
    <li>
      <a href="#">Reports</a>
      <ul>
        <li><router-link to="/report/timeSheet">Timesheets</router-link></li>
        <li><router-link to="/report/allowance">Allowance</router-link></li>
        <li><router-link to="/report/ppe">PPE</router-link></li>
        <li><router-link to="/report/training">Training</router-link></li>
      </ul>
    </li>
    <li>
      <a href="#help">Help</a>
      <ul>
        <li>
          <router-link to="/app-doc">User Guide</router-link>
        </li>
        <li>
          <router-link to="/faq">FAQ</router-link>
        </li>
      </ul>
    </li>
  </ul>

</nav>
</template>

<script>
// import { ActionTypes } from '@/utils/constants'
import { userService } from '@/services'
import Roles from '@/utils/roles'
// import store from '@/store'

export default {
  name: 'AppNavigation',
  data () {
    return {
      roleName: '',
      appRoles: {},
      userRoles: [],
      appRoleList: [
        {
          roleName: '',
          show: false
        }
      ]
    }
  },
  methods: {
    userInRoles (roles) {
      let showMenu = false
      if (roles.includes('|')) {
        const roleArray = roles.split('|')
        roleArray.forEach(roleName => {
          const findRole = this.userRoles.find(role => role === roleName)
          if (findRole !== undefined) {
            showMenu = true
          }
        })
      } else {
        const isInRole = this.userRoles.find(role => role === roles)
        if (isInRole !== undefined) {
          showMenu = true
        }
      }

      return showMenu
    }
  },
  mounted () {
    this.appRoles = Roles
    userService.getCurrentUser().then(res => {
      this.roleName = res.data.roleName
      this.userRoles = res.data.roles
    })
  }
}
</script>

<style scoped lang="scss">

</style>
